import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Input,
	Heading,
	Grid,
	Textarea,
	IconButton,
	Image,
	useColorModeValue,
	useBreakpointValue,
	Spinner,
} from '@chakra-ui/react'
import { AddIcon, DeleteIcon, ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import { useState } from 'react'
import s1 from './templates/s1.png'
import s2 from './templates/s2.png'
import s3 from './templates/s3.png'
import s4 from './templates/s4.png'

function App() {
	const [participants, setParticipants] = useState([''])
	const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0)
	const [loading, setLoading] = useState(false) // Spinner state
	const [formData, setFormData] = useState({
		trainingEmail: '',
		trainingName: '',
		companyName: '',
		trainingDate: '',
		instructorSignature: '',
		trainingScope: '',
	})

	const templates = [
		{ image: s1, name: 'Szablon 1' },
		{ image: s2, name: 'Szablon 4' },
		{ image: s3, name: 'Szablon 2' },
		{ image: s4, name: 'Szablon 3' },
	]

	const addParticipant = () => {
		if (participants.length < 5) {
			setParticipants([...participants, ''])
		}
	}

	const removeParticipant = index => {
		const newParticipants = participants.filter((_, i) => i !== index)
		setParticipants(newParticipants)
	}

	const handleParticipantChange = (index, value) => {
		const newParticipants = participants.map((p, i) => (i === index ? value : p))
		setParticipants(newParticipants)
	}

	const handleNextTemplate = () => {
		setSelectedTemplateIndex(prevIndex => (prevIndex + 1) % templates.length)
		console.log(selectedTemplateIndex)
	}

	const handlePreviousTemplate = () => {
		setSelectedTemplateIndex(prevIndex => (prevIndex === 0 ? templates.length - 1 : prevIndex - 1))
	}

	const handleInputChange = e => {
		setFormData({
			...formData,
			[e.target.id]: e.target.value,
		})
	}

	const handleSubmit = async e => {
		e.preventDefault()
		setLoading(true)
		const submissionData = {
			trainingEmail: formData.trainingEmail,
			trainingName: formData.trainingName,
			companyName: formData.companyName,
			trainingDate: formData.trainingDate,
			instructorSignature: formData.instructorSignature,
			trainingScope: formData.trainingScope,
			participants: participants,
			selectedTemplate: templates[selectedTemplateIndex].name,
		}
		console.log('Sending data:', submissionData)

		try {
			const response = await fetch(
				'https://script.google.com/macros/s/AKfycbyveSZqQSkEDEqsYkvY3Ehlu4xWaywBf6hcRLl1bSAri0vM07S9wPJsruZoMiuxXmJL/exec',
				{
					method: 'POST',
					mode: 'no-cors', // Ogranicza dostęp do szczegółów odpowiedzi
					body: JSON.stringify(submissionData),
					headers: {
						'Content-Type': 'application/json',
					},
				}
			)

			console.log('Fetch completed:', response) // Logowanie odpowiedzi (choć przy no-cors może być puste)

			// Nie masz dostępu do treści odpowiedzi w trybie no-cors, ale możemy sprawdzić, czy fetch zakończył się sukcesem
			if (response.ok) {
				console.log('Request successful, but no content is accessible due to CORS policy.')
			} else {
				console.error('Request failed:', response.status, response.statusText)
			}

			alert('Certyfikaty zostały wygenerowane i wysłane na podany adres email.')
		} catch (error) {
			console.error('Error occurred during fetch:', error) // Logowanie błędu
			alert('Wystąpił błąd podczas generowania certyfikatów.')
		} finally {
			setLoading(false)
		}
	}

	const pageBg = useColorModeValue(
		'linear-gradient(135deg, rgba(165,188,205,1) 0%, rgba(255,255,255,1) 100%)',
		'linear-gradient(135deg, #0D2744 0%, #274E6A 100%)'
	)
	const cardBg = useColorModeValue('white', 'gray.200')
	const inputBg = useColorModeValue('white', 'gray.200')
	const textColor = useColorModeValue('black', 'gray.900')
	const inputBorderColor = useColorModeValue('gray.400', 'black')
	const inputerHoverBorderColor = useColorModeValue('gray.500', 'gray.800')
	const placeholderColor = useColorModeValue('black', 'black')
	const focusBorderColor = useColorModeValue('teal.600', '#102b48')
	const headingColor = useColorModeValue('teal', '#102b48')
	const buttonBg = useColorModeValue('teal', 'gray.800')
	const buttonTextColor = useColorModeValue('white', 'gray.200')
	const hoverButtonColor = useColorModeValue('teal.500', 'gray.600')

	const isMobile = useBreakpointValue({ base: true, md: false })

	return (
		<Box minH='100vh' p={6} bg={pageBg}>
			<Box maxW='container.md' mx='auto' p={6}>
				<Heading mb={6} textAlign='center'>
					Generator Certyfikatów
				</Heading>

				{/* Form wrapped with onSubmit */}
				<Box as='form' onSubmit={handleSubmit}>
					{/* Sekcja Informacje o Szkoleniu */}
					<Box p={6} bg={cardBg} borderWidth='1px' borderRadius='lg' mb={6} shadow='md'>
						<Heading size='md' mb={4} color={headingColor}>
							Informacje o szkoleniu
						</Heading>
						<Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6}>
							<FormControl id='trainingName'>
								<FormLabel color={textColor}>Nazwa szkolenia</FormLabel>
								<Input
									type='text'
									placeholder='Wpisz nazwę szkolenia'
									focusBorderColor={focusBorderColor}
									borderColor={inputBorderColor}
									_placeholder={{ color: placeholderColor }}
									bg={inputBg}
									_hover={{ bgBorder: inputerHoverBorderColor }}
									color={textColor}
									value={formData.trainingName}
									onChange={handleInputChange}
								/>
							</FormControl>

							<FormControl id='companyName'>
								<FormLabel color={textColor}>Firma prowadząca szkolenie</FormLabel>
								<Input
									type='text'
									placeholder='Wpisz nazwę firmy'
									focusBorderColor={focusBorderColor}
									borderColor={inputBorderColor}
									_placeholder={{ color: placeholderColor }}
									bg={inputBg}
									_hover={{ bgBorder: inputerHoverBorderColor }}
									color={textColor}
									value={formData.companyName}
									onChange={handleInputChange}
								/>
							</FormControl>

							<FormControl id='trainingDate'>
								<FormLabel color={textColor}>Data szkolenia</FormLabel>
								<Input
									type='date'
									focusBorderColor={focusBorderColor}
									borderColor={inputBorderColor}
									_placeholder={{ color: placeholderColor }}
									bg={inputBg}
									_hover={{ bgBorder: inputerHoverBorderColor }}
									color={textColor}
									value={formData.trainingDate}
									onChange={handleInputChange}
								/>
							</FormControl>

							<FormControl id='instructorSignature'>
								<FormLabel color={textColor}>Podpis osoby prowadzącej</FormLabel>
								<Input
									type='text'
									placeholder='Podpis'
									focusBorderColor={focusBorderColor}
									borderColor={inputBorderColor}
									_placeholder={{ color: placeholderColor }}
									bg={inputBg}
									_hover={{ bgBorder: inputerHoverBorderColor }}
									color={textColor}
									value={formData.instructorSignature}
									onChange={handleInputChange}
								/>
							</FormControl>
						</Grid>

						<FormControl id='trainingScope' mt={6}>
							<FormLabel color={textColor}>Zakres szkolenia</FormLabel>
							<Textarea
								placeholder='Uzupełnij zakres szkolenia'
								focusBorderColor={focusBorderColor}
								borderColor={inputBorderColor}
								_placeholder={{ color: placeholderColor }}
								bg={inputBg}
								_hover={{ bgBorder: inputerHoverBorderColor }}
								color={textColor}
								value={formData.trainingScope}
								onChange={handleInputChange}
							/>
						</FormControl>
					</Box>

					{/* Sekcja Uczestnicy */}
					<Box p={6} bg={cardBg} borderWidth='1px' borderRadius='lg' mb={6} shadow='md'>
						<Heading size='md' mb={4} color={headingColor}>
							Uczestnicy ({participants.length}/5)
						</Heading>
						{participants.map((participant, index) => (
							<Grid templateColumns='1fr auto' gap={4} mb={2} key={index}>
								<Input
									placeholder={`Uczestnik ${index + 1}`}
									value={participant}
									onChange={e => handleParticipantChange(index, e.target.value)}
									focusBorderColor={focusBorderColor}
									borderColor={inputBorderColor}
									_placeholder={{ color: placeholderColor }}
									bg={inputBg}
									_hover={{ bgBorder: inputerHoverBorderColor }}
									color={textColor}
								/>
								<IconButton
									icon={<DeleteIcon />}
									onClick={() => removeParticipant(index)}
									isDisabled={participants.length === 1}
									bg={buttonBg}
									color={buttonTextColor}
									_hover={{ bg: hoverButtonColor }}
								/>
							</Grid>
						))}
						<Button
							leftIcon={<AddIcon />}
							onClick={addParticipant}
							isDisabled={participants.length >= 5}
							bg={buttonBg}
							color={buttonTextColor}
							_hover={{ bg: hoverButtonColor }}>
							Dodaj uczestnika
						</Button>
					</Box>

					{/* Sekcja Wybór Szablonu */}
					<Box p={6} bg={cardBg} borderWidth='1px' borderRadius='lg' mb={6} shadow='md'>
						<Heading size='md' mb={4} color={headingColor}>
							Wybierz szablon certyfikatu
						</Heading>

						{isMobile ? (
							<Box display='flex' flexDirection='column' alignItems='center'>
								<Image
									src={templates[selectedTemplateIndex].image}
									alt={templates[selectedTemplateIndex].name}
									width='100%'
									height='auto'
									objectFit='contain'
									borderRadius='lg'
									border='2px solid'
									borderColor={buttonBg}
									mb={4}
								/>
								<Box display='flex' justifyContent='center' gap={4}>
									<IconButton
										icon={<ArrowLeftIcon />}
										onClick={handlePreviousTemplate}
										aria-label='Poprzedni szablon'
										bg={buttonBg}
										color={buttonTextColor}
										_hover={{ bg: hoverButtonColor }}
									/>
									<IconButton
										icon={<ArrowRightIcon />}
										onClick={handleNextTemplate}
										aria-label='Następny szablon'
										bg={buttonBg}
										color={buttonTextColor}
										_hover={{ bg: hoverButtonColor }}
									/>
								</Box>
							</Box>
						) : (
							<Box display='flex' alignItems='center' justifyContent='space-between'>
								<IconButton
									icon={<ArrowLeftIcon />}
									onClick={handlePreviousTemplate}
									aria-label='Poprzedni szablon'
									bg={buttonBg}
									color={buttonTextColor}
									_hover={{ bg: hoverButtonColor }}
								/>
								<Image
									src={templates[selectedTemplateIndex].image}
									alt={templates[selectedTemplateIndex].name}
									width='80%'
									height='auto'
									objectFit='contain'
									borderRadius='lg'
									border='2px solid'
									borderColor={buttonBg}
								/>
								<IconButton
									icon={<ArrowRightIcon />}
									onClick={handleNextTemplate}
									aria-label='Następny szablon'
									bg={buttonBg}
									color={buttonTextColor}
									_hover={{ bg: hoverButtonColor }}
								/>
							</Box>
						)}
					</Box>

					{/* Sekcja Email */}
					<Box p={6} bg={cardBg} borderWidth='1px' borderRadius='lg' mb={6} shadow='md'>
						<Heading size='md' mb={4} color={headingColor}>
							Email do wysyłki certyfikatów
						</Heading>
						<FormControl id='trainingEmail'>
							<Input
								type='email'
								placeholder='Wpisz email'
								focusBorderColor={focusBorderColor}
								borderColor={inputBorderColor}
								_placeholder={{ color: placeholderColor }}
								bg={inputBg}
								_hover={{ bgBorder: inputerHoverBorderColor }}
								color={textColor}
								value={formData.trainingEmail}
								onChange={handleInputChange}
							/>
						</FormControl>
					</Box>

					{/* Przycisk Generowania */}
					<Button
						bg={buttonBg}
						color={buttonTextColor}
						_hover={{ bg: hoverButtonColor }}
						size='lg'
						w='100%'
						mt={6}
						type='submit'>
						{loading ? <Spinner size='md' /> : 'Generuj certyfikaty'}
					</Button>
				</Box>
			</Box>
		</Box>
	)
}

export default App
